import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="H" transform="translate(11.000000, 5.000000)">
        <path
          d="M27.6,30.3 L27.6,61 L33.6,61 L33.6,48.2 L43.6,48.2 L43.6,61 L49.6,61 L49.6,30.3 L43.6,30.3 L43.6,42.2 L33.6,42.2 L33.6,30.3 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
              L 11, 27
              L 11, 72
              L 50, 95
              L 89, 73
              L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
